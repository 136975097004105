import { Controller } from '@hotwired/stimulus'
import { breakpoints } from '../constants/Layout'

export default class extends Controller {
  static outlets = ['content-switcher']

  DEMO_DAY_BANNER_HEIGHT = 50
  FIXED_NAV_SCROLL_HEIGHT_DESKTOP = 32
  FIXED_NAV_SCROLL_HEIGHT_MOBILE = 0
  STICKY_NAV_CLASS = 'new-navbar--parent--sticky'

  selectorExitListener = null
  frameExitListener = null
  frameEnterListener = null
  focusOutListener = null

  utilityBar = null
  careersSecondaryNav = null
  careersSecondaryNavDefaultTop = null

  selectorExitDeactivationTimeout = null
  utilityBarHeight = this.FIXED_NAV_SCROLL_HEIGHT_DESKTOP
  fixedNavScrollHeight = this.utilityBarHeight
  lastScrollTop = 0

  connect() {
    this.lastScrollTop = window.scrollY
    this.utilityBar = this.element.querySelector('.header-utility-bar')
    this.careersSecondaryNav = document.querySelector('.careers-secondary-stick-nav-bar')

    // Careers secondary nav bar needs to take into account the height of the utility bar
    if (this.careersSecondaryNav) {
      this.careersSecondaryNavDefaultTop = Number(getComputedStyle(this.careersSecondaryNav).top.match(/\d+/)[0])

      // Hack for initial page load when refreshing below the stick point
      this.careersSecondaryNav.style.top = `${this.careersSecondaryNavDefaultTop + 32  }px`
    }

    window.addEventListener('scroll', this.handleScroll.bind(this))
    window.addEventListener('resize', this.handleResize.bind(this))

    window.navbar = this

    this.handleScroll()
  }

  handleScroll() {
    this.calculateFixedNavScrollHeight()

    const scrollTop = window.scrollY
    const shouldStickNav = scrollTop > this.fixedNavScrollHeight
    const hasStickyNav = this.element.parentElement.classList.contains(this.STICKY_NAV_CLASS)
    const isUtilityBarHidden = this.utilityBar.classList.contains('header-utility-bar--hidden')
    const isScrollingDown = scrollTop > this.lastScrollTop;

    (() => {
      if (shouldStickNav && isScrollingDown && !isUtilityBarHidden) {
        this.utilityBar.classList.add('header-utility-bar--hidden')
        document.body.classList.add('body--short-navbar')
        if (this.careersSecondaryNav)
          this.careersSecondaryNav.style.top = `${this.careersSecondaryNavDefaultTop  }px`
        return
      }

      if (!isScrollingDown && isUtilityBarHidden) {
        this.utilityBar.classList.remove('header-utility-bar--hidden')
        document.body.classList.remove('body--short-navbar')
        if (this.careersSecondaryNav)
          this.careersSecondaryNav.style.top = `${this.careersSecondaryNavDefaultTop + 32  }px`
        return
      }
    })();

    (() => {
      if (shouldStickNav && !hasStickyNav) {
        this.element.parentElement.classList.add(this.STICKY_NAV_CLASS)

        // The height transition on the utility bar should be present while the nav is sticky,
        // but not when the nav is first made sticky.
        // I typically use requestAnimationFrame for these types of issues, but it doesn't work here.
        // I've exhausted all other options...
        setTimeout(() => {
          this.utilityBar.classList.add('header-utility-bar--transition')
        }, 10)
        return
      }

      if (hasStickyNav && scrollTop <= Math.max(this.fixedNavScrollHeight - this.utilityBarHeight, 0)) {
        this.element.parentElement.classList.remove(this.STICKY_NAV_CLASS)
        this.utilityBar.classList.remove('header-utility-bar--transition')
        return
      }
    })()

    this.lastScrollTop = scrollTop
  }

  handleResize() {
    this.calculateFixedNavScrollHeight()
  }

  calculateFixedNavScrollHeight() {
    const headerBanners = document.querySelector('#header_banners')
    const bannerHeight = headerBanners ? headerBanners.clientHeight : 0

    if (window.innerWidth > breakpoints.navMainLarge) {
      this.utilityBarHeight = this.FIXED_NAV_SCROLL_HEIGHT_DESKTOP
    } else {
      this.utilityBarHeight = this.FIXED_NAV_SCROLL_HEIGHT_MOBILE
    }

    this.fixedNavScrollHeight = this.utilityBarHeight + bannerHeight
  }

  /* Mega menu content switcher functionality */

  contentSwitcherOutletConnected() {
    // .bind() creates a new function, so we can't remove the listener without storing a reference
    this.selectorExitListener = this.handleSelectorExit.bind(this)
    this.frameEnterListener = this.handleFrameEnter.bind(this)
    this.frameExitListener = this.handleFrameExit.bind(this)
    this.focusOutListener = this.handleFocusOut.bind(this)

    this.contentSwitcherOutlet.selectorTargets.forEach((selector, i) => {
      selector.addEventListener('click', () => {
        const { activeSelector } = this.contentSwitcherOutlet
        if (activeSelector == selector) {
          this.deactivateCurrentSelector()
        } else {
          this.contentSwitcherOutlet.activateSelector(selector)
          const frame = this.contentSwitcherOutlet.selectorFrameMap.get(selector)
          frame.addEventListener('focusout', this.focusOutListener)
        }
      })
    })

    this.contentSwitcherOutlet.onSelectorActivate = (selector) => {
      clearTimeout(this.selectorExitDeactivationTimeout)
      const frame = this.contentSwitcherOutlet.selectorFrameMap.get(selector)
      selector.addEventListener('mouseleave', this.selectorExitListener)
      frame.addEventListener('mouseenter', this.frameEnterListener)
      frame.addEventListener('mouseleave', this.frameExitListener)
    }
  }

  handleFocusOut(event) {
    requestAnimationFrame(() => {
      // Do nothing if focus is still in the frame
      if (this.contentSwitcherOutlet.currentFrame?.contains(document.activeElement)) return

      // Deactivate the selector if focus is outside the frame
      this.deactivateCurrentSelector()
    })
  }

  handleSelectorExit() {
    // If mouse is moving from selector to frame then this will be cleared by handleFrameEnter()
    // Otherwise, the selector will be deactivated after a short delay
    this.selectorExitDeactivationTimeout = setTimeout(() => {
      this.deactivateCurrentSelector()
    }, 50)
  }

  handleFrameEnter() {
    clearTimeout(this.selectorExitDeactivationTimeout)
  }

  handleFrameExit() {
    this.deactivateCurrentSelector()
  }

  deactivateCurrentSelector() {
    const { activeSelector, currentFrame } = this.contentSwitcherOutlet
    activeSelector?.removeEventListener('mouseleave', this.selectorExitListener)
    currentFrame?.removeEventListener('mouseenter', this.frameEnterListener)
    currentFrame?.removeEventListener('mouseleave', this.frameExitListener)
    currentFrame?.removeEventListener('focusout', this.focusOutListener)
    this.contentSwitcherOutlet.deactivateCurrentSelector()
  }
}
