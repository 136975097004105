import { Controller } from '@hotwired/stimulus'
import { breakpoints } from '../constants/Layout'

export default class extends Controller {
  static targets = ['back', 'logo', 'openButtons', 'closedButtons']
  static outlets = ['pager']

  navMenu = null

  isOpen = false

  connect() {
    this.navMenu = document.querySelector('.nav--mobile-menu')

    addEventListener('resize', () => {
      if (this.isOpen && window.innerWidth > breakpoints.navMainLarge) {
        this.closeNavbar(true)
      }
    })
  }

  openNavbar() {
    this.isOpen = true
    this.openButtonsTarget.classList.remove('hidden')
    this.closedButtonsTarget.classList.add('hidden')
    this.navMenu.classList.remove('hidden')
    this.pagerOutlet.activatePageKey('nav')
    this.setChatWidgetVisibility(false)
    
    // navMenu has 'hidden' by default, so we need to wait for the next frame to prevent pop-in
    requestAnimationFrame(() => {
      this.navMenu.classList.add('nav--mobile-open')
    })
  }

  closeNavbar(skipTransition = false) {
    this.isOpen = false
    this.hideBackButton()
    this.openButtonsTarget.classList.add('hidden')
    this.closedButtonsTarget.classList.remove('hidden')
    this.navMenu.classList.remove('nav--mobile-open')

    const onClose = () => {
      this.navMenu.classList.add('hidden')
      this.pagerOutlet.resetPager()
      this.setChatWidgetVisibility(true)
    }

    // Skip transition when resized to desktop
    if (skipTransition) {
      onClose()
    } else {
      this.navMenu.addEventListener('transitionend', onClose, { once: true })
    }
  }

  pagerOutletConnected() {
    this.pagerOutlet.onPageChange(() => {
      if (!this.isOpen) return;
      if (this.pagerOutlet.historyStack.length > 0) {
        this.showBackButton()
      } else {
        this.hideBackButton()
      }
    })
  }

  showBackButton() {
    this.logoTarget.classList.add('hidden')
    this.backTarget.classList.remove('hidden')
  }

  hideBackButton() {
    this.logoTarget.classList.remove('hidden')
    this.backTarget.classList.add('hidden')
  }

  goBack() {
    this.pagerOutlet.back()
  }

  setChatWidgetVisibility(visible) {
    if (!window.zE) return

    if (visible) {
      window.zE('webWidget', 'show')
    } else {
      window.zE('webWidget', 'hide')
    }
  }
}
