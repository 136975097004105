import { Controller } from '@hotwired/stimulus'
import { breakpoints } from '../constants/Layout'

export default class extends Controller {
  static values = { index: Number }

  FIXED_NAV_SCROLL_HEIGHT_DESKTOP = 32
  FIXED_NAV_SCROLL_HEIGHT_MOBILE = 0
  STICKY_NAV_CLASS = 'new-navbar--parent--sticky'

  selectorExitListener = null
  frameExitListener = null
  frameEnterListener = null
  focusOutListener = null

  utilityBar = null
  careersSecondaryNav = null
  careersSecondaryNavDefaultTop = null

  selectorExitDeactivationTimeout = null
  utilityBarHeight = this.FIXED_NAV_SCROLL_HEIGHT_DESKTOP
  fixedNavScrollHeight = this.utilityBarHeight
  lastScrollTop = 0

  connect() {
    this.lastScrollTop = window.scrollY
    this.utilityBar = document.querySelector('.header-utility-bar')
    this.careersSecondaryNav = document.querySelector('.careers-secondary-stick-nav-bar')

    // Careers secondary nav bar needs to take into account the height of the utility bar
    if (this.careersSecondaryNav) {
      this.careersSecondaryNavDefaultTop = Number(getComputedStyle(this.careersSecondaryNav).top.match(/\d+/)[0])

      // Hack for initial page load when refreshing below the stick point
      this.careersSecondaryNav.style.top = `${this.careersSecondaryNavDefaultTop + 32  }px`
    }

    window.addEventListener('scroll', this.handleScroll.bind(this))
    window.addEventListener('resize', this.handleResize.bind(this))

    window.navbar = this

    this.handleScroll()
  }

  handleScroll() {
    this.calculateFixedNavScrollHeight()

    const scrollTop = window.scrollY
    const shouldStickNav = scrollTop > this.fixedNavScrollHeight
    const hasStickyNav = this.element.parentElement.classList.contains(this.STICKY_NAV_CLASS)
    const isUtilityBarHidden = this.utilityBar.classList.contains('header-utility-bar--hidden')
    const isScrollingDown = scrollTop > this.lastScrollTop;

    (() => {
      if (shouldStickNav && isScrollingDown && !isUtilityBarHidden) {
        this.utilityBar.classList.add('header-utility-bar--hidden')
        document.body.classList.add('body--short-navbar')
        if (this.careersSecondaryNav)
          this.careersSecondaryNav.style.top = `${this.careersSecondaryNavDefaultTop  }px`
        return
      }

      if (!isScrollingDown && isUtilityBarHidden) {
        this.utilityBar.classList.remove('header-utility-bar--hidden')
        document.body.classList.remove('body--short-navbar')
        if (this.careersSecondaryNav)
          this.careersSecondaryNav.style.top = `${this.careersSecondaryNavDefaultTop + 32  }px`
        return
      }
    })();

    (() => {
      if (shouldStickNav && !hasStickyNav) {
        this.element.parentElement.classList.add(this.STICKY_NAV_CLASS)

        // The height transition on the utility bar should be present while the nav is sticky,
        // but not when the nav is first made sticky.
        // I typically use requestAnimationFrame for these types of issues, but it doesn't work here.
        // I've exhausted all other options...
        setTimeout(() => {
          this.utilityBar.classList.add('header-utility-bar--transition')
        }, 10)
        return
      }

      if (hasStickyNav && scrollTop <= Math.max(this.fixedNavScrollHeight - this.utilityBarHeight, 0)) {
        this.element.parentElement.classList.remove(this.STICKY_NAV_CLASS)
        this.utilityBar.classList.remove('header-utility-bar--transition')
        return
      }
    })()

    this.lastScrollTop = scrollTop
  }

  handleResize() {
    this.calculateFixedNavScrollHeight()
  }

  calculateFixedNavScrollHeight() {
    const headerBanners = document.querySelector('#header_banners')
    const bannerHeight = headerBanners ? headerBanners.clientHeight : 0

    if (window.innerWidth > breakpoints.navMainLarge) {
      this.utilityBarHeight = this.FIXED_NAV_SCROLL_HEIGHT_DESKTOP
    } else {
      this.utilityBarHeight = this.FIXED_NAV_SCROLL_HEIGHT_MOBILE
    }

    this.fixedNavScrollHeight = this.utilityBarHeight + bannerHeight
  }
  
  openMobileNav() {
    const mobileDropdown = document.getElementsByClassName('navbar-v2-mobile-dropdown')
    const navbarMobileIconButtons = document.getElementsByClassName('navbar-v2-mobile-icon-buttons')
    const navbarMobileCloseButton = document.getElementsByClassName('navbar-v2-mobile-close-button')
    if (
      mobileDropdown.length === 0 ||
      navbarMobileIconButtons === 0 ||
      navbarMobileCloseButton === 0
    ) {
      return
    }
    mobileDropdown[0].classList.remove('navbar-v2-hidden')
    mobileDropdown[0].classList.add('navbar-v2-active')

    navbarMobileIconButtons[0].classList.remove('navbar-v2-active')
    navbarMobileIconButtons[0].classList.add('navbar-v2-hidden')

    navbarMobileCloseButton[0].classList.remove('navbar-v2-hidden')
    navbarMobileCloseButton[0].classList.add('navbar-v2-active')
  }

  closeMobileNav() {
    const mobileDropdown = document.getElementsByClassName('navbar-v2-mobile-dropdown')
    const navbarMobileIconButtons = document.getElementsByClassName('navbar-v2-mobile-icon-buttons')
    const navbarMobileCloseButton = document.getElementsByClassName('navbar-v2-mobile-close-button')
    if (
      mobileDropdown.length === 0 ||
      navbarMobileIconButtons === 0 ||
      navbarMobileCloseButton === 0
    ) {
      return
    }
    mobileDropdown[0].classList.remove('navbar-v2-active')
    mobileDropdown[0].classList.add('navbar-v2-hidden')

    navbarMobileIconButtons[0].classList.remove('navbar-v2-hidden')
    navbarMobileIconButtons[0].classList.add('navbar-v2-active')

    navbarMobileCloseButton[0].classList.remove('navbar-v2-active')
    navbarMobileCloseButton[0].classList.add('navbar-v2-hidden')
  }

  toggleMobileDropdown() {
    const index = this.indexValue
    const mobilePlatformDropdowns = document.getElementsByClassName(
      'navbar-v2-mobile-category-wrapper'
    )
    const mobileExpandButtons = document.getElementsByClassName('navbar-v2-mobile-expand-button')
    if (mobilePlatformDropdowns.length === 0 || mobileExpandButtons.length === 0) {
      return
    }
    if (index > mobilePlatformDropdowns.length || index > mobileExpandButtons.length) {
      return
    }
    if (mobilePlatformDropdowns[index].classList.contains('navbar-v2-active')) {
      mobilePlatformDropdowns[index].classList.remove('navbar-v2-active')
      mobileExpandButtons[index].classList.remove('navbar-v2-active')
    } else {
      mobilePlatformDropdowns[index].classList.add('navbar-v2-active')
      mobileExpandButtons[index].classList.add('navbar-v2-active')

      const otherCategories = Array.from(mobilePlatformDropdowns).filter(
        (value, arrIndex) => index !== arrIndex
      )

      const otherButtons = Array.from(mobileExpandButtons).filter(
        (value, arrIndex) => index !== arrIndex
      )

      otherCategories.forEach(otherCategory => {
        otherCategory.classList.remove('navbar-v2-active')
      })

      otherButtons.forEach(otherButton => {
        otherButton.classList.remove('navbar-v2-active')
      })
    }
  }

  toggleDesktopDropdown() {
    const index = this.indexValue
    const menuDropdowns = document.getElementsByClassName('navbar-v2-menu-dropdown')
    const menuItemButtons = document.getElementsByClassName('navbar-v2-menu-item')

    if (menuDropdowns.length === 0 || menuItemButtons.length === 0) {
      return
    }
    if (index > menuDropdowns.length || index > menuItemButtons.length) {
      return
    }

    if (menuDropdowns[index].classList.contains('navbar-v2-active')) {
      menuDropdowns[index].classList.remove('navbar-v2-active')
      menuItemButtons[index].classList.remove('navbar-v2-active')
    } else {
      menuDropdowns[index].classList.add('navbar-v2-active')
      menuItemButtons[index].classList.add('navbar-v2-active')

      const otherCategories = Array.from(menuDropdowns).filter(
        (value, arrIndex) => index !== arrIndex
      )

      const otherButtons = Array.from(menuItemButtons).filter(
        (value, arrIndex) => index !== arrIndex
      )

      otherCategories.forEach(otherCategory => {
        otherCategory.classList.remove('navbar-v2-active')
      })

      otherButtons.forEach(otherButton => {
        otherButton.classList.remove('navbar-v2-active')
      })
    }
  }

  closeDesktopDropdown() {
    const menuDropdowns = document.getElementsByClassName('navbar-v2-menu-dropdown')
    const menuItemButtons = document.getElementsByClassName('navbar-v2-menu-item')

    if (menuDropdowns.length === 0 || menuItemButtons.length === 0) {
      return
    }

    const menuDropdownsArr = Array.from(menuDropdowns)
    menuDropdownsArr.forEach(menuDropdown => {
      menuDropdown.classList.remove('navbar-v2-active')
    })

    const menuItemButtonsArr = Array.from(menuItemButtons)
    menuItemButtonsArr.forEach(menuItemButton => {
      menuItemButton.classList.remove('navbar-v2-active')
    })
  }
}
